import * as React from "react";

import { Link } from "gatsby";

const GalleryButton = ({ children, classStyle, link }) => {
    return (
        <>
            <Link
                className={classStyle}
                href={link ? link : "/galleries"}
            >
                {children ? children : "Gallery"}
            </Link>
        </>
    )
}

export default GalleryButton;