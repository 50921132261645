import * as React from "react"
import { StaticImage } from "gatsby-plugin-image";

import Seo from '../components/seo';
import Layout from '../components/layout';
import GalleryButton from "../components/gallery-btn";

import * as indexStyles from '../styles/index.module.css';

// markup
const IndexPage = () => {
  return (
    <Layout>
      <Seo
        title="Andre Cho Fine Arts Studio"
        description="Andre Cho's Fine Art Studio. Now taking commissions."
      />
        <main>
          <section style={{ display: "grid" }} className={indexStyles.hero}>
            <StaticImage
              className="heroImg"
              style={{
                gridArea: "1/1",
                opacity: ".3",
                zIndex: "-3"
              }}
              layout="fullWidth"
              alt=""
              src="../images/home/hero.jpg"
              formats={["auto", "webp", "avif"]}
            />
            <div 
              style={{
                gridArea: "1/1",
                position: "relative",
                placeItems: "center",
                display: "grid",
              }}
              
            >
              <div className={indexStyles.heroContent}>
                <h1 id="heroTitle" className={indexStyles.heroTitle}>Andre Cho Fine Arts Studio</h1>

                <GalleryButton classStyle={indexStyles.heroBtn}>Gallery</GalleryButton>
              </div>
            </div>
          </section>
        </main>
    </Layout>
    
  )
}

export default IndexPage
